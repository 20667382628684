var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-2" },
    [
      _c(
        "psi-form-element",
        {
          attrs: {
            label: _vm.label,
            "label-class": "text-subtitle-1",
            required: _vm.required
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "my-0 py-0", attrs: { outlined: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "mt-n2 mb-n6 py-0 d-flex" },
                [
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mt-0",
                      model: {
                        value: _vm.radios,
                        callback: function($$v) {
                          _vm.radios = $$v
                        },
                        expression: "radios"
                      }
                    },
                    [
                      _c("v-radio", {
                        staticClass: "my-0 py-0",
                        attrs: {
                          value: "single",
                          dense: "",
                          "hide-details": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "grey--text": _vm.radios === "range"
                                        }
                                      },
                                      [_vm._v("Single Page:")]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { width: "150px" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "ml-4 mt-5",
                                          attrs: {
                                            placeholder: "Page Number",
                                            label: "Page Number",
                                            solo: "",
                                            dense: "",
                                            disabled: _vm.radios === "range"
                                          },
                                          on: { change: _vm.updatePage }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("v-radio", {
                        staticClass: "mt-n5 py-0",
                        attrs: {
                          value: "range",
                          dense: "",
                          "hide-details": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "grey--text": _vm.radios === "single"
                                        }
                                      },
                                      [_vm._v("Page Range:")]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { width: "150px" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "ml-4 mt-5",
                                          attrs: {
                                            label: "From",
                                            placeholder: "From",
                                            solo: "",
                                            dense: "",
                                            disabled: _vm.radios === "single"
                                          },
                                          on: { change: _vm.updateFrom }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { width: "150px" } },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "ml-4 mt-5",
                                          attrs: {
                                            label: "To",
                                            placeholder: "To",
                                            solo: "",
                                            dense: "",
                                            disabled: _vm.radios === "single"
                                          },
                                          on: { change: _vm.updateTo }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      ref: "btn",
                      staticClass: "mt-4",
                      attrs: { icon: "", color: "error darken-2" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("remove")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }