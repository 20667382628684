import { render, staticRenderFns } from "./PsiSplitPages.vue?vue&type=template&id=51f98539&scoped=true&"
import script from "./PsiSplitPages.vue?vue&type=script&lang=js&"
export * from "./PsiSplitPages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f98539",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VIcon,VRadio,VRadioGroup,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51f98539')) {
      api.createRecord('51f98539', component.options)
    } else {
      api.reload('51f98539', component.options)
    }
    module.hot.accept("./PsiSplitPages.vue?vue&type=template&id=51f98539&scoped=true&", function () {
      api.rerender('51f98539', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/uploads/components/Media/PsiSplitPages.vue"
export default component.exports