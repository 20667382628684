<template>
    <div class="mb-2">
        <psi-form-element
            :label="label"
            label-class="text-subtitle-1"
            :required="required"
        >
            <v-card outlined class="my-0 py-0">
                <v-card-text class="mt-n2 mb-n6 py-0 d-flex">
                    <v-radio-group v-model="radios" class="mt-0">
                        <v-radio
                            value="single"
                            dense
                            hide-details
                            class="my-0 py-0"
                        >
                            <template v-slot:label>
                                <div class="d-flex align-center">
                                    <span
                                        :class="{
                                            'grey--text': radios === 'range',
                                        }"
                                        >Single Page:</span
                                    >
                                    <div style="width: 150px">
                                        <v-text-field
                                            placeholder="Page Number"
                                            class="ml-4 mt-5"
                                            label="Page Number"
                                            solo
                                            dense
                                            :disabled="radios === 'range'"
                                            @change="updatePage"
                                        ></v-text-field>
                                    </div>
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="range"
                            dense
                            hide-details
                            class="mt-n5 py-0"
                        >
                            <template v-slot:label>
                                <div class="d-flex align-center">
                                    <span
                                        :class="{
                                            'grey--text': radios === 'single',
                                        }"
                                        >Page Range:</span
                                    >
                                    <div style="width: 150px">
                                        <v-text-field
                                            label="From"
                                            class="ml-4 mt-5"
                                            placeholder="From"
                                            solo
                                            dense
                                            :disabled="radios === 'single'"
                                            @change="updateFrom"
                                        ></v-text-field>
                                    </div>
                                    <div style="width: 150px">
                                        <v-text-field
                                            label="To"
                                            class="ml-4 mt-5"
                                            placeholder="To"
                                            solo
                                            dense
                                            :disabled="radios === 'single'"
                                            @change="updateTo"
                                        ></v-text-field>
                                    </div>
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-spacer></v-spacer>
                    <v-btn
                        ref="btn"
                        icon
                        color="error darken-2"
                        class="mt-4"
                        @click.stop="$emit('remove')"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-card-text>
            </v-card>
        </psi-form-element>
    </div>
</template>
<script>
export default {
    name: "psi-split-pages",
    components: {},
    props: {
        pages: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            radios: "single",
            pagesData: this.pages,
        };
    },
    computed: {},
    watch: {},
    methods: {
        updatePage(page) {
            this.pagesData.from = parseInt(page);
            this.pagesData.to = parseInt(page);
            this.$emit("update:pages", this.pagesData);
        },
        updateFrom(page) {
            this.pagesData.from = parseInt(page);
            this.$emit("update:pages", this.pagesData);
        },
        updateTo(page) {
            this.pagesData.to = parseInt(page);
            this.$emit("update:pages", this.pagesData);
        },
    },
    mounted() {
        this.$refs.btn.$el.tabIndex = -1;
    },
};
</script>

<style scoped>
</style>